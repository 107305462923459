import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class IsAdminGuard  {

  constructor(
    private router: Router,
    private authService: AuthService,
    private matSnackBar: MatSnackBar,
  ) {
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService
      .isAdmin$()
      .pipe(
        tap((isAdmin: boolean) => {
          if (!isAdmin) {
            this.matSnackBar.open('Du hast nicht die notwendigen Berechtigungen!', 'OK');
            this.router.navigateByUrl('/');
          }
        }),
      );
  }
}
