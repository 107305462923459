import { Component, OnInit } from '@angular/core';
import { NewsItemListDto } from '@tv/api';
import { NewsService } from '@tv/news/news.service';
import { NewsUiService } from '@tv/news/news.ui-service';
import { Observable } from 'rxjs';

@Component({
  selector: 'tv-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {

  public newsItemList$: Observable<NewsItemListDto[]>;

  constructor(private newsService: NewsService,
              private newsUiService: NewsUiService) {
  }

  ngOnInit() {
    this.newsItemList$ = this.newsService
      .listNewsItems$();
  }

  openNewNewsDialog(): void {
    this.newsUiService.openNewNewsDialog();
  }

}
