import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ActDto } from '@tv/api';
import { TitleService } from '@tv/core';
import { FileUploadService } from '@tv/shared/file-upload.service';
import { FileUploader } from 'ng2-file-upload';
import { Subscription } from 'rxjs';
import { ActService } from '../act.service';

@Component({
  selector: 'tv-act-details',
  templateUrl: './act-details.component.html',
  styleUrls: ['./act-details.component.scss'],
})
export class ActDetailsComponent implements OnInit, OnDestroy {

  act: ActDto;
  edit = false;
  uploader: FileUploader;
  mainImage?: string;
  private routeParamsSubscription: Subscription;

  constructor(private activatedRoute: ActivatedRoute,
              private actService: ActService,
              private titleService: TitleService,
              private fileUploadService: FileUploadService) {
  }

  actUpdated(act: ActDto): void {
    this.edit = false;
    this.act = act;
  }

  ngOnInit(): void {
    this.routeParamsSubscription =
      this.activatedRoute
        .params
        .subscribe((params: Params) => {
          const id = +params['id'];

          this.uploader = this.fileUploadService.prepareAutoUploader({
            url: `api/act/${id}/image`,
          });
          this.uploader.onSuccessItem = (_, response: string) => {
            this.mainImage = response;
          };

          this.actService
            .getAct(id)
            .then((act: ActDto) => {
              this.act = act;
              this.titleService.setPageTitle(act.title);
            });

          this.actService
            .getActImages(id)
            .subscribe(imageUuids => {
              if (imageUuids.length > 0) {
                this.mainImage = imageUuids[imageUuids.length - 1];
              }
            });
        });
  }

  ngOnDestroy(): void {
    if (this.routeParamsSubscription) {
      this.routeParamsSubscription.unsubscribe();
    }
  }
}
