<mat-card *ngIf="!editNewsItem">
  <mat-card-title>{{newsItem.title}}</mat-card-title>
  <div *ngIf="mainImageUuid" mat-card-image>
    <img [alt]="'Main image of '+newsItem.title"
         [src]="getImageUrl(mainImageUuid)"
         [tvImageBrowser]="getImageUrls()">
  </div>
  <mat-card-content>
    <div [tvMarkdown]="newsItem.description"></div>


    <div *ngIf="newsItem.imageUuids.length > 1"
         class="tv-news-item-images">
      <ng-container
        *ngFor="let imageUuid of newsItem.imageUuids; index as index; first as first">
        <mat-card *ngIf="!first" class="tv-news-item-images__image mat-card--image-only">
          <img [alt]="'Image '+index+' of '+newsItem.title"
               [src]="getImageUrl(imageUuid)"
               [tvImageBrowser]="getImageUrls()"
               matCardImage>
        </mat-card>
      </ng-container>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button (click)="loadEditDetails()"
            *tvIsAdmin
            mat-button
            type="button">
      Bearbeiten
    </button>
  </mat-card-actions>
</mat-card>

<tv-news-item-editor (cancel)="editNewsItem = null"
                     (newsItemCreated)="newsItemUpdated($event)"
                     (newsItemUpdated)="newsItemUpdated($event)"
                     *ngIf="editNewsItem"
                     [newsItem]="editNewsItem"></tv-news-item-editor>
