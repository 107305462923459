import { Component, Input } from '@angular/core';
import { RoleDto } from '@tv/api';

@Component({
  selector: 'tv-role-viewer',
  templateUrl: './role-viewer.component.html',
  styleUrls: ['./role-viewer.component.scss'],
})
export class RoleViewerComponent {

  @Input() roles: RoleDto[];
}
