import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NewsItemDetailsDto, NewsItemEditDto } from '@tv/api';
import { NewsService } from '@tv/news/news.service';
import { FileUploadService } from '@tv/shared/file-upload.service';
import { FileItem, FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'tv-news-item-editor',
  templateUrl: './news-item-editor.component.html',
  styleUrls: ['./news-item-editor.component.scss'],
})
export class NewsItemEditorComponent implements OnInit {

  editNewsItem: NewsItemEditDto;
  uploader: FileUploader;

  @Output() newsItemCreated = new EventEmitter<NewsItemDetailsDto>();
  @Output() newsItemUpdated = new EventEmitter<NewsItemDetailsDto>();
  @Output() cancel = new EventEmitter<MouseEvent>();

  constructor(private newsService: NewsService,
              private fileUploadService: FileUploadService) {
  }

  @Input() set newsItem(newsItemDto: NewsItemDetailsDto) {
    // copy the value -> we don't want to edit the original
    this.editNewsItem = Object.assign({updateAuthor: false}, newsItemDto);
  }

  ngOnInit(): void {
    this.uploader = this.fileUploadService.prepareAutoUploader({url: `api/news-item/${this.editNewsItem.id}/image`});
    this.uploader.onSuccessItem = (_: FileItem, response: string) => {
      this.editNewsItem.imageUuids.push(response);
    };
  }

  updateNewsItem(): void {
    if (this.editNewsItem.id) {
      this.newsService
        .updatNewsItem(this.editNewsItem)
        .then(this.newsItemCreated.emit.bind(this.newsItemCreated));
    } else {
      this.newsService
        .createNewsItem(this.editNewsItem)
        .then(this.newsItemUpdated.emit.bind(this.newsItemUpdated));
    }
  }

  moveBy(fromIdx: number, offset: number) {
    const imageUuids = this.editNewsItem.imageUuids;
    const tmp = imageUuids[fromIdx + offset];
    imageUuids[fromIdx + offset] = imageUuids[fromIdx];
    imageUuids[fromIdx] = tmp;
  }
}
