<mat-form-field *ngIf="!preview">
  <textarea (ngModelChange)="onChange($event)"
            [disabled]="disabled"
            [name]="name"
            [ngModel]="value"
            [placeholder]="placeholder"
            [required]="required"
            matInput>
  </textarea>
</mat-form-field>
<div *ngIf="preview"
     class="tv-markdown-preview-wrapper">
  <span class="mat-input-placeholder-wrapper">
    <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control   -->
    <label class="mat-input-placeholder mat-float">{{placeholder}}</label>
  </span>
  <div [tvMarkdown]="value"></div>
</div>
<div class="tv-markdown-input__preview-button-container">
  <button (click)="preview = !preview"
          [title]="preview ? 'Bearbeiten' : 'Vorschau'"
          class="tv-markdown-input__preview-button"
          mat-icon-button
          type="button">
    <mat-icon>{{preview ? 'code' : 'subject'}}</mat-icon>
  </button>
</div>
