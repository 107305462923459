import { Component, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { CredentialsDto, UserInfoDto } from '@tv/api';
import { AuthService } from '../auth.service';

@Component({
  selector: 'tv-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  userInfo: UserInfoDto;
  loginActive = false;
  credentials: CredentialsDto = {};

  constructor(private matSnackBar: MatSnackBar,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.authService
      .getCurrentUser$()
      .subscribe((userInfo: UserInfoDto) => {
        this.userInfo = userInfo;
      });
  }

  reset(): void {
    this.userInfo = null;
    this.credentials = {};
    this.loginActive = false;
  }

  logout(): void {
    this.authService
      .logout()
      .subscribe(() => this.reset());
  }

  login(): void {
    this.authService
      .login(this.credentials)
      .subscribe((userInfo: UserInfoDto) => {
        this.userInfo = userInfo;
        this.matSnackBar
          .open('Login erfolgreich!', 'Ok', {duration: 1000});
      });
  }
}
