import { Directive, ElementRef, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ImageBrowserComponent, ImageBrowserData } from '@tv/shared/image-browser/image-browser.component';

@Directive({
  selector: 'img[tvImageBrowser]',
})
export class ImageBrowserDirective implements OnInit {

  @Input() tvImageBrowser: string[];
  @HostBinding('title')
  title = 'Klicken zum vergrößern';
  private img: HTMLMediaElement;

  constructor(el: ElementRef,
              private matDialog: MatDialog) {
    this.img = el.nativeElement;
  }

  ngOnInit(): void {
    this.img.classList.add('tv-clickable');
  }

  @HostListener('click')
  onClick() {
    const data: ImageBrowserData = {
      active: this.img.attributes['src']['value'],
      available: this.tvImageBrowser,
    };
    this.matDialog.open(ImageBrowserComponent, {data: data});
  }

}
