<div aria-hidden="true" class="separator"></div>
<img [routerLink]="['/']"
     class="theater-vogelweide-logo"
     alt="TheaterVogelweide"
     src="assets/images/TheaterVogelweide--header.png">
<h1>{{pageTitle()}}</h1>
<tv-login></tv-login>
<button (click)="toggleMenu()"
        mat-icon-button
        type="button">
  <mat-icon>menu</mat-icon>
</button>
