import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PersonDto } from '@tv/api';
import { PersonService } from '@tv/person/person-service/person.service';

@Component({
  selector: 'tv-person-editor',
  templateUrl: './person-editor.component.html',
  styleUrls: ['./person-editor.component.scss'],
})
export class PersonEditorComponent {

  @Output() personUpdated = new EventEmitter<PersonDto>();
  @Output() cancel: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  editPerson: PersonDto;

  constructor(private personService: PersonService) {
  }

  @Input() set person(person: PersonDto) {
    this.editPerson = Object.assign({}, person);
  }

  update(): void {
    this.personService
      .udpate(this.editPerson)
      .then((person: PersonDto) => this.personUpdated.emit(person));
  }
}
