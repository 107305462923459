import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { createErrorHandler } from '@sentry/angular-ivy';

export const SpringErrorProperties = [
  'error',
  'exception',
  'message',
  'path',
  'status',
  'timestamp',
];

export interface SpringError {
  error: string;
  exception: string;
  message: string;
  path: string;
  status: number;
  timestamp: number;
}

const sentryErrorHandler = createErrorHandler({
  showDialog: false,
  logErrors: true,
});


@Injectable()
export class ErrorHandlerService implements ErrorHandler {

  constructor(private injector: Injector, private ngZone: NgZone) {
  }

  handleError(error: unknown): void {
    sentryErrorHandler.handleError(error);

    if (error instanceof HttpErrorResponse) {
      this.handleResponseErrors(error.error);
    } else {
      this.showSnackbar(error.toString());
    }

    console.log(error);
  }

  private showSnackbar(message: string) {
    // for some reason we seem to be outside the angular zone here, so clicking the action won't be handled correctly
    this.ngZone.run(() => {
      this.injector.get(MatSnackBar)
        .open(message, 'OK', {duration: 5000});
    });
  }

  private handleResponseErrors(response: HttpErrorResponse): void {
    try {
      if (this.isSpringError(response.error) || 'message' in response.error) {
        this.showSnackbar(response.error.message);
        return;
      }
    } catch (error) {
      console.error('Error during handling of response error', error);
    }

    if (response.error) {
      this.showSnackbar(response.error);
    } else {
      this.showSnackbar(`${response.status} - ${response.statusText}`);
    }
  }

  private isSpringError(error: unknown): error is SpringError {
    if (!error) {
      return false;
    }

    for (const key of SpringErrorProperties) {
      if (!Object.prototype.hasOwnProperty.call(error, key)) {
        return false;
      }

    }

    return true;
  }
}
