<header class="tv-dialog__header">
  <div class="tv-dialog__header__title">
    Neuigkeit erfassen
  </div>
</header>
<form (submit)="createNewsItem()">
  <div class="tv-dialog__content">
    <mat-form-field>
      <input [(ngModel)]="newNewsItem.title"
             matInput
             name="title"
             placeholder="Titel">
    </mat-form-field>
    <mat-form-field>
      <input [(ngModel)]="newNewsItem.activeFrom"
             [matDatepicker]="activeFromPicker"
             matInput
             name="activeFrom"
             placeholder="Verfügbar ab">
      <mat-datepicker-toggle [for]="activeFromPicker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #activeFromPicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <input [(ngModel)]="newNewsItem.activeTo"
             [matDatepicker]="activeToPicker"
             matInput
             name="activeTo"
             placeholder="Verfügbar bis">
      <mat-datepicker-toggle [for]="activeToPicker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #activeToPicker></mat-datepicker>
    </mat-form-field>
    <tv-markdown-input [(ngModel)]="newNewsItem.description"
                       [name]="'description'"
                       [placeholder]="'Beschreibung'"></tv-markdown-input>

  </div>
  <div class="tv-dialog__actions">
    <button (click)="close()" mat-button type="button">Abbrechen</button>
    <button mat-button type="submit">Hinzufügen</button>
  </div>
</form>
