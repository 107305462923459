<mat-card class="tv-person-list--search-form">
  <mat-card-header>
    <mat-card-title>
      Personensuche
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field>
      <input (ngModelChange)="onSearchTextChange($event)"
             [ngModel]="searchText"

             matInput
             name="searchText"
             placeholder="Suchtext">
      <mat-hint>Vorname, Nachname oder E-Mail Addresse</mat-hint>
    </mat-form-field>
  </mat-card-content>
</mat-card>

<div class="tv-person-list--search-results">
  <tv-person-card (actionClick)="personAction($event, person)"
                  (click)="selectPerson.emit(person)"
                  *ngFor="let person of filteredPersons"
                  [person]="person">
  </tv-person-card>
</div>
