<button (click)="loginActive = true;"
        *ngIf="!loginActive && !userInfo"
        mat-button
        type="button">Mitglieder Login
</button>
<ng-template [ngIf]="userInfo">
  <div>
    <span>Hallo {{userInfo.email}}</span>
    <button
      (click)="logout();"
      mat-button
      type="button">Logout
    </button>
  </div>
</ng-template>
<form (submit)="login();"
      *ngIf="loginActive && !userInfo">
  <mat-card>
    <mat-card-content>
      <mat-form-field [color]="'accent'">
        <input [(ngModel)]="credentials.username"

               id="username"
               matInput
               name="username"
               placeholder="Benutzername"
               type="email"></mat-form-field>
      <mat-form-field [color]="'accent'">
        <input [(ngModel)]="credentials.password"
               id="password"
               matInput
               name="password"
               placeholder="Passwort"
               type="password"></mat-form-field>
    </mat-card-content>
    <mat-card-actions>
      <button (click)="reset();"
              mat-button
              type="reset">Abbrechen
      </button>
      <button mat-button
              type="submit">Login
      </button>
    </mat-card-actions>
  </mat-card>
</form>
