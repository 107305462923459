<header class="tv-dialog__header">
  <div class="tv-dialog__header__title">
    Neues Stück erfassen
  </div>
</header>
<form (submit)="createAct()">
  <div class="tv-dialog__content">
    <mat-form-field>
      <input [(ngModel)]="newAct.year"
             matInput
             name="year"
             placeholder="Jahr">
    </mat-form-field>
    <mat-form-field>
      <input [(ngModel)]="newAct.title"
             matInput
             name="title"
             placeholder="Titel">
    </mat-form-field>
    <tv-markdown-input [(ngModel)]="newAct.description"
                       [name]="'description'"
                       [placeholder]="'Beschreibung'"></tv-markdown-input>
  </div>
  <div class="tv-dialog__actions">
    <button (click)="close()" mat-button type="button">Abbrechen</button>
    <button mat-button type="submit">Hinzufügen</button>
  </div>
</form>
