<mat-card *ngIf="person && !edit">
  <mat-card-header>
    <img [alt]="person.firstName + ' ' + person.lastName"
         [src]="person.email | gravatarUrl"
         mat-card-avatar
    >
    <mat-card-title>
      {{person.firstName}} {{person.lastName}}
    </mat-card-title>
    <mat-card-subtitle>
      {{person.email}}
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="tv-person-detail__details">
      <div *ngIf="person.phone"><strong>Telefon</strong> {{person.phone}}</div>
      <div *ngIf="person.mobilePhone"><strong>Mobil</strong> {{person.mobilePhone}}</div>
      <div *ngIf="person.address">
        <div>{{person.address.street}} {{person.address.houseNumber}}</div>
        <div>{{person.address.zipcode}} {{person.address.city}}</div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <ng-template [ngIf]="!edit">
      <button (click)="edit = true;" mat-button type="button">Bearbeiten</button>
    </ng-template>
  </mat-card-actions>
</mat-card>
<tv-person-editor (cancel)="edit = false;"
                  (personUpdated)="personUpdated($event)"
                  *ngIf="person && edit"
                  [person]="person"></tv-person-editor>
<mat-card *ngIf="person?.personRoleHistories">
  <mat-card-header>
    <mat-card-title>
      Bisherige Rollen / Aufgaben ({{person.personRoleHistories.length}})
    </mat-card-title>
  </mat-card-header>
  <mat-table [dataSource]="person.personRoleHistories">
    <ng-container matColumnDef="year">
      <mat-header-cell *matHeaderCellDef>Jahr</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.act.year}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actName">
      <mat-header-cell *matHeaderCellDef mat-header-cell>Stück</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <a [routerLink]="['/archive', element.act.id]">{{element.act.title}}</a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="role">
      <mat-header-cell *matHeaderCellDef mat-header-cell>Rolle</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.roleName}}</mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="visibleColumns"></mat-header-row>
    <mat-row *matRowDef="let element; columns: visibleColumns"></mat-row>
  </mat-table>
</mat-card>
