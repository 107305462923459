import { Component } from '@angular/core';

@Component({
  selector: 'tv-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss'],
})
export class ImprintComponent {

  imprint = `**Vereinssitz**
Salzburgerstraße 80
4600 Wels

**Obmann**
Johann Salzinger
+43 699 12310780

**Bankverbindung**
Verein Theater Vogelweide
IBAN: AT35 3477 0000 0578 2420
`;
}
