import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { PersonDto } from '@tv/api';
import { TitleService } from '@tv/core';
import { PersonService } from '@tv/person/person-service/person.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'tv-person-details',
  templateUrl: './person-details.component.html',
  styleUrls: ['./person-details.component.scss'],
})
export class PersonDetailsComponent implements OnInit, OnDestroy {

  person: PersonDto;
  visibleColumns = ['year', 'actName', 'role'];
  edit = false;
  private routeParamsSubscription: Subscription;

  constructor(private activatedRoute: ActivatedRoute,
              private personService: PersonService,
              private titleService: TitleService) {
  }

  personUpdated(person: PersonDto): void {
    this.edit = false;
    this.person = person;
  }

  ngOnInit(): void {
    this.routeParamsSubscription =
      this.activatedRoute
        .params
        .subscribe((params: Params) => {
          const id = +params['id'];

          this.personService
            .get(id)
            .then((person: PersonDto) => {
              this.person = person;
              this.titleService.setPageTitle(`${person.firstName} ${person.lastName}`);
            });
        });
  }

  ngOnDestroy(): void {
    if (this.routeParamsSubscription) {
      this.routeParamsSubscription.unsubscribe();
    }
  }
}
