<mat-card *ngIf="act && !edit">
  <mat-card-title>
    {{act.title}}
  </mat-card-title>
  <mat-card-subtitle>
    {{act.year}}
  </mat-card-subtitle>
  <ng-template [tvIsAdminElse]="mainActImage" tvIsAdmin>
    <div #dropZone
         (fileOver)="dropZone['fileOver']= $event"
         [ngClass]="{
          'tv-act-images__main-image-uploader--file-over': !!dropZone['fileOver'],
          'tv-act-details__main-image-placeholder': !mainImage
          }"
         [uploader]="uploader"
         class="tv-act-details__main-image-uploader"
         mat-card-image
         ng2FileDrop>
      <img *ngIf="!!mainImage;"
           [alt]="'Main image of '+act.title"
           [src]="'/api/act/'+act.id+'/image/'+mainImage">
    </div>
  </ng-template>
  <ng-template #mainActImage>
    <div mat-card-image>
      <img
        *ngIf="!!mainImage;"
        [alt]="'Main image of '+act.title"
        [src]="'/api/act/'+act.id+'/image/'+mainImage">
    </div>
  </ng-template>

  <mat-card-content>
    <div class="tv-act-detail__details">
      <div [tvMarkdown]="act.description"></div>
      <tv-role-viewer [roles]="act.roles"></tv-role-viewer>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <ng-template [ngIf]="!edit">
      <button (click)="edit = true;"
              *tvIsAdmin
              mat-button
              type="button">
        Bearbeiten
      </button>
    </ng-template>
  </mat-card-actions>
</mat-card>
<tv-act-editor (actCreated)="actUpdated($event)"
               (actUpdated)="actUpdated($event)"
               (cancel)="edit = false;"
               *ngIf="act && edit"
               [act]="act"></tv-act-editor>
