import { Directive, EmbeddedViewRef, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from './auth.service';

@Directive({
  selector: '[tvIsAdmin]',
})
export class IsAdminDirective implements OnInit, OnDestroy {

  @Input() tvIsAdminElse: TemplateRef<unknown>;
  private isAdmin$: Subscription;
  private isAdminViewRev: EmbeddedViewRef<unknown>;
  private isAdminViewElseRev: EmbeddedViewRef<unknown>;

  constructor(private templateRef: TemplateRef<unknown>,
              private viewContainer: ViewContainerRef,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    this.authService
      .isAdmin$()
      .subscribe((isAdmin: boolean) => {
        if (isAdmin) {
          if (!this.isAdminViewRev) {
            this.viewContainer.clear();
            this.isAdminViewRev = this.viewContainer.createEmbeddedView(this.templateRef);
            this.isAdminViewElseRev = null;
          }
        } else {
          this.viewContainer.clear();
          if (!!this.tvIsAdminElse && !this.isAdminViewElseRev) {
            this.isAdminViewRev = null;
            this.isAdminViewElseRev = this.viewContainer.createEmbeddedView(this.tvIsAdminElse);
          }
        }
      });
  }

  ngOnDestroy(): void {
    if (this.isAdmin$) {
      this.isAdmin$.unsubscribe();
    }
  }

}
