import { Component, HostBinding, HostListener, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

export interface ImageBrowserData {
  active: string;
  available?: string[];
}

@Component({
  selector: 'tv-image-browser',
  templateUrl: './image-browser.component.html',
  styleUrls: ['./image-browser.component.scss'],
})
export class ImageBrowserComponent {

  availableImages: string[];
  activeIndex = 0;

  constructor(private matDialogRef: MatDialogRef<ImageBrowserComponent>,
              @Inject(MAT_DIALOG_DATA) data: ImageBrowserData) {
    this.availableImages = [].concat(data.available);
    this.activeIndex = this.availableImages.findIndex((it) => it === data.active);

    if (this.activeIndex === -1) {
      this.availableImages.unshift(data.active);
      this.activeIndex = 0;
    }
  }

  get prevEnabled(): boolean {
    return this.activeIndex > 0;
  }

  get nextEnabled(): boolean {
    return this.activeIndex < this.availableImages.length - 1;
  }

  @HostBinding('tabindex')
  get tabindex(): number {
    return 0;
  }

  @HostListener('keyup.ArrowLeft')
  prev(): void {
    if (this.prevEnabled) {
      this.activeIndex--;
    }
  }

  @HostListener('keyup.ArrowRight')
  next(): void {
    if (this.nextEnabled) {
      this.activeIndex++;
    }
  }
}
