import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';
import { ImprintComponent } from '@tv/imprint/imprint/imprint.component';
import { ActDetailsComponent } from './archive/act-details/act-details.component';
import { ArchiveComponent } from './archive/archive.component';
import { NewsComponent } from './news/news.component';

const INDEX_ROUTE: Route = {
  path: '',
  pathMatch: 'full',
  title: 'Neuigkeiten',
  component: NewsComponent,
};

const ARCHIVE_ROUTES: Routes = [
  {
    path: 'archive/:id',
    component: ActDetailsComponent,
  }, {
    path: 'archive',
    title: 'Archiv',
    component: ArchiveComponent,
  },
];

const TV_ROUTES: Routes = [
  INDEX_ROUTE,
  ...ARCHIVE_ROUTES,
  {
    path: 'sponsors',
    loadChildren: () => import('@tv/sponsor').then(m => m.SponsorModule),
  },
  {
    path: 'imprint',
    title: 'Impressum',
    component: ImprintComponent,
  },
  {
    path: 'tickets',
    loadChildren: () => import('./tickets/tickets.module').then(m => m.TicketsModule),
  },
  {
    path: 'about-us',
    loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(TV_ROUTES, {})],
  exports: [RouterModule],
  providers: [],
})
export class TvRoutingModule {
}
