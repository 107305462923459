import { Injectable, Signal, signal } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';

@Injectable()
export class TitleService extends TitleStrategy {
  private pageTitle = signal<string | null>(null);


  constructor(private title: Title) {
    super();
  }

  updateTitle(snapshot: RouterStateSnapshot): void {
    this.setPageTitle(this.buildTitle(snapshot) ?? null);
  }

  setPageTitle(title: string | null): void {
    this.pageTitle.set(title);
    this.title.setTitle(`Theater Vogelweide - ${title}`);
  }

  getPageTitle(): Signal<string | null> {
    return this.pageTitle;
  }
}
