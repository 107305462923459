import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@tv/core';
import { ImprintModule } from '@tv/imprint/imprint.module';
import { MemberModule } from '@tv/member';
import { TvRoutingModule } from 'app/tv-routing.module';
import { ArchiveModule } from './archive/archive.module';
import { NewsModule } from './news/news.module';
import { PersonModule } from './person';
import { TvComponent } from './tv.component';
import { TraceService } from '@sentry/angular-ivy';
import { Router } from '@angular/router';

@NgModule({
  imports: [
    CoreModule.forRoot(),
    TvRoutingModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NewsModule,
    PersonModule,
    ArchiveModule,
    MemberModule,
    ImprintModule,
  ],
  providers: [
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        // empty - used for eager initialization of the sentry trace service
      },
      deps: [TraceService],
      multi: true,
    },
  ],
  declarations: [
    TvComponent,
  ],
  bootstrap: [TvComponent],
})
export class TvModule {
}
