import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CredentialsDto, UserInfoDto } from '@tv/api';
import { ADMIN } from '@tv/core/authorities';
import * as Sentry from '@sentry/browser';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class AuthService {
  private currentUser: UserInfoDto;
  private currentUser$: Subject<UserInfoDto> = new ReplaySubject<UserInfoDto>(1);

  constructor(private http: HttpClient) {
    this.loadCurrentUser()
      .pipe(
        take(1),
      )
      .subscribe(() => {
        // TODO - this is a hack to initiate loading the current user eagerly
      });
  }

  logout(): Observable<void> {
    return this.http.post('/api/logout', null)
      .pipe(
        switchMap(() => {
          return this.loadCurrentUser();
        }),
        map(() => {
          // TODO - this is a hack to clear the current user context and return a void observable
        }),
      );
  }

  login(credentials: CredentialsDto): Observable<UserInfoDto> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http
      .post('/api/login', `username=${encodeURIComponent(credentials.username)}&password=${encodeURIComponent(credentials.password)}`, {
        headers: headers,
      })
      .pipe(
        switchMap(() => this.loadCurrentUser()),
      );
  }

  loadCurrentUser(): Observable<UserInfoDto | null> {
    return this.http.get<UserInfoDto | null>('/api/user/current')
      .pipe(
        tap((user: UserInfoDto | null) => {
          Sentry.setUser(user && {email: user.email});
          this.currentUser = user;
          this.currentUser$.next(user);
        }),
      );
  }

  getCurrentUser(): UserInfoDto {
    return this.currentUser;
  }

  getCurrentUser$(): Observable<UserInfoDto> {
    return this.currentUser$.asObservable();
  }

  isLoggedIn$(): Observable<boolean> {
    return this.getCurrentUser$()
      .pipe(
        map((user: UserInfoDto) => !!user),
      );
  }

  getCurrentUserAuthorities(): string[] {
    const user = this.getCurrentUser();
    return user ? user.authorities : [];
  }

  getCurrentUserAuthorities$(): Observable<string[]> {
    return this.getCurrentUser$()
      .pipe(
        map((user: UserInfoDto) => user ? user.authorities : []),
      );
  }

  isAdmin(): boolean {
    return this.getCurrentUserAuthorities().includes(ADMIN);
  }

  isAdmin$(): Observable<boolean> {
    return this.getCurrentUserAuthorities$()
      .pipe(
        map(authorities => authorities.includes(ADMIN)),
      );
  }
}
