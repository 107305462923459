import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';

@Injectable()
export class MenuService {
  open = false;

  constructor(private router: Router) {
    this.router.events
      .subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
          this.open = false;
        }
      });
  }
}
