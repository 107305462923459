import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from './auth.service';

@Directive({
  selector: '[tvIsLoggedIn]',
})
export class IsLoggedInDirective implements OnInit, OnDestroy {

  private isLoggedIn$: Subscription;

  constructor(private templateRef: TemplateRef<unknown>,
              private viewContainer: ViewContainerRef,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    this.authService
      .isLoggedIn$()
      .subscribe((isLoggedIn: boolean) => {
        if (isLoggedIn) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.isLoggedIn$) {
      this.isLoggedIn$.unsubscribe();
    }
  }
}
