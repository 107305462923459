import { NgModule } from '@angular/core';
import { CoreModule } from '@tv/core';
import { SharedModule } from '../shared';
import { AddressEditorComponent } from './address-editor/address-editor.component';
import { PersonCardComponent } from './person-card/person-card.component';
import { PersonCreatorComponent } from './person-creator/person-creator.component';
import { PersonDetailsComponent } from './person-details/person-details.component';
import { PersonEditorComponent } from './person-editor/person-editor.component';
import { PersonFormComponent } from './person-form/person-form.component';
import { PersonListPageComponent } from './person-list-page/person-list-page.component';
import { PersonListComponent } from './person-list/person-list.component';
import { PersonRoutingModule } from './person-routing.module';
import { PersonService } from './person-service/person.service';

@NgModule({
  imports: [
    SharedModule,
    CoreModule,
    PersonRoutingModule,
  ],
  declarations: [
    PersonCardComponent,
    PersonListComponent,
    PersonListPageComponent,
    PersonEditorComponent,
    AddressEditorComponent,
    PersonDetailsComponent,
    PersonCreatorComponent,
    PersonFormComponent,
  ],
  providers: [
    PersonService,
  ],
})
export class PersonModule {
}
