import { Directive, HostBinding, Input, OnChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { marked } from 'marked';

@Directive({
  selector: '[tvMarkdown]',
})
export class MarkdownDirective implements OnChanges {

  @Input() tvMarkdown: string;
  @HostBinding('innerHTML') html: SafeHtml;

  constructor(private domSanitizer: DomSanitizer) {
  }

  @HostBinding('class.tv-markdown') get tvMarkdownClass(): boolean {
    return true;
  }

  ngOnChanges(): void {
    const md = marked.setOptions({
      breaks: true,
      mangle: false,
      headerIds: false,
    });
    this.html =
      this.domSanitizer.bypassSecurityTrustHtml(
        md.parse((this.tvMarkdown || '').replace(/</g, '&lt;')));
  }
}
