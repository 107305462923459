<form (submit)="updateAct()">
  <mat-card>
    <mat-card-content>
      <mat-form-field>
        <input [(ngModel)]="editAct.title" [placeholder]="'Titel'"

               matInput
               name="title"
               type="text"></mat-form-field>
      <mat-form-field>
        <input [(ngModel)]="editAct.year" [placeholder]="'Jahr'"
               matInput
               name="year"
               type="text"></mat-form-field>
      <tv-markdown-input [(ngModel)]="editAct.description"
                         [name]="'description'"
                         [placeholder]="'Beschreibung'">
      </tv-markdown-input>

      <tv-role-editor [roles]="editAct.roles"></tv-role-editor>
    </mat-card-content>
    <mat-card-actions>
      <button (click)="cancel.emit($event)" mat-button type="button">Abbrechen</button>
      <button mat-button type="submit">Aktualisieren</button>
    </mat-card-actions>
  </mat-card>
</form>
