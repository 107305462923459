import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PersonCreateDto, PersonDto } from '@tv/api';
import { Observable, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class PersonService {
  private loaded: boolean;
  private personsSubject: ReplaySubject<PersonDto[]> = new ReplaySubject<PersonDto[]>(1);

  constructor(private http: HttpClient) {
  }

  list(): Observable<PersonDto[]> {
    if (!this.loaded) {
      this.load();
    }
    return this.personsSubject.asObservable();
  }

  load(): void {
    this.loaded = true;
    this.http
      .get<PersonDto[]>('/api/person')
      .toPromise()
      .then((persons: PersonDto[]) => this.personsSubject.next(persons));
  }

  get(id: number): Promise<PersonDto> {
    return this.http
      .get<PersonDto>(`/api/person/${id}`)
      .toPromise();
  }

  create(newPerson: PersonCreateDto): Promise<PersonDto> {
    return this.http
      .post<PersonDto>('/api/person', newPerson)
      .pipe(
        tap(() => this.load()),
      )
      .toPromise();
  }

  udpate(updatePerson: PersonCreateDto): Promise<PersonDto> {
    return this.http
      .put<PersonDto>('/api/person', updatePerson)
      .pipe(
        tap(() => this.load()),
      )
      .toPromise();
  }

  delete(personId: number): Promise<void> {
    return this.http
      .delete(`/api/person/${personId}`)
      .pipe(
        map(() => this.load()),
      )
      .toPromise();
  }
}
