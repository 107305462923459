<div class="tv-archive__acts-container">
  <ng-template [ngForOf]="groupedActs.years" let-year ngFor>
    <div class="tv-archive__year-separator">{{year}}</div>
    <tv-act-card *ngFor="let act of groupedActs[year]"
                 [act]="act"
                 [routerLink]="[act.id]"></tv-act-card>
  </ng-template>
</div>
<button (click)="openNewActDialog()" *tvIsAdmin
        aria-label="Neues Stück erfassen"
        class="tv-fab"
        mat-fab>
  <mat-icon>add</mat-icon>
</button>
