<mat-nav-list>
  <a [routerLink]="['/']" mat-list-item>
    <span matLine>Startseite</span>
  </a>
  <a *tvIsAdmin [routerLink]="['/news']" mat-list-item>
    <span matLine>Neuigkeiten</span>
  </a>
  <a [routerLink]="['/tickets']" mat-list-item>
    <span matLine>Kartenreservierung</span>
  </a>
  <a [routerLink]="['/archive']" mat-list-item>
    <span matLine>Archiv</span>
  </a>
  <a [routerLink]="['/about-us/board-members']" mat-list-item>
    <span matLine>Vorstand</span>
  </a>
  <a *tvIsLoggedIn [routerLink]="['/person']" mat-list-item>
    <span matLine>Personen</span>
  </a>
  <a [routerLink]="['/sponsors']" mat-list-item>
    <span matLine>Sponsoren</span>
  </a>
  <a href="http://theaterkids.magix.net" mat-list-item>
    <span matLine>theaterKIDS</span>
    <img alt="theaterKIDS"
         class="theaterkids-logo"
         src="assets/images/TheaterKids.gif">
  </a>
</mat-nav-list>
<tv-login></tv-login>
<img class="theaterkids-logo"
     alt="theaterkids"
     src="assets/images/TheaterKids.gif">
