import { Component, Input, OnInit } from '@angular/core';
import { ActDto } from '../../../api';

@Component({
  selector: 'tv-act-card',
  templateUrl: './act-card.component.html',
  styleUrls: ['./act-card.component.scss'],
})
export class ActCardComponent implements OnInit {

  @Input() act: ActDto;

  ngOnInit() {
    if (!this.act) {
      throw new Error('Missing input: act');
    }
  }

}
