import { Component, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { NewsItemDetailsDto, NewsItemListDto } from '@tv/api';
import { NewsService } from '@tv/news/news.service';

export interface NewsItemListDtoChange extends SimpleChange {
  previousValue: NewsItemListDto;
  currentValue: NewsItemListDto;
}

export interface NewsItemComponentChanges extends SimpleChanges {
  newsItem?: NewsItemListDtoChange;
}

@Component({
  selector: 'tv-news-item',
  templateUrl: './news-item.component.html',
  styleUrls: ['./news-item.component.scss'],
})
export class NewsItemComponent implements OnChanges {

  @Input() newsItem: NewsItemListDto;

  editNewsItem?: NewsItemDetailsDto;
  mainImageUuid?: string;

  constructor(private newsService: NewsService) {
  }

  ngOnChanges(changes: NewsItemComponentChanges): void {
    if (changes.newsItem) {
      const currentValue = changes.newsItem.currentValue;
      if (currentValue.imageUuids.length !== 0) {
        this.mainImageUuid = currentValue.imageUuids[0];
      } else {
        this.mainImageUuid = null;
      }
    }
  }

  loadEditDetails(): void {
    this.newsService
      .loadDetails(this.newsItem.id)
      .then(details => this.editNewsItem = details);
  }

  newsItemUpdated(newsItem: NewsItemDetailsDto): void {
    this.newsItem = newsItem;
    this.editNewsItem = null;
  }

  getImageUrl(uuid: string): string {
    return `/api/news-item/${this.newsItem.id}/image/${uuid}`;
  }

  getImageUrls(): string[] {
    return this.newsItem
      .imageUuids
      .map((uuid) => this.getImageUrl(uuid));
  }
}
