import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '@tv/core';
import { RoleEditorComponent } from 'app/archive/role-editor/role-editor.component';
import { RoleViewerComponent } from 'app/archive/role-viewer/role-viewer.component';
import { SharedModule } from '../shared';
import { ActCardComponent } from './act-card/act-card.component';
import { ActCreatorComponent } from './act-creator/act-creator.component';
import { ActDetailsComponent } from './act-details/act-details.component';
import { ActEditorComponent } from './act-editor/act-editor.component';
import { ActService } from './act.service';
import { ArchiveComponent } from './archive.component';

@NgModule({
  imports: [
    CoreModule,
    SharedModule,
    ReactiveFormsModule,
  ],
  declarations: [
    ArchiveComponent,
    ActCardComponent,
    ActCreatorComponent,
    ActDetailsComponent,
    ActEditorComponent,
    RoleEditorComponent,
    RoleViewerComponent,
  ],
  providers: [
    ActService,
  ],
})
export class ArchiveModule {
}
