import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { SharedModule } from '@tv/shared';
import { AuthService } from './auth.service';
import { ErrorHandlerService } from './error-handler/error-handler.service';
import { HasAuthorityDirective } from './has-authority.directive';
import { HeaderComponent } from './header/header.component';
import { IsAdminDirective } from './is-admin.directive';
import { IsAdminGuard } from './is-admin.guard';
import { IsLoggedInDirective } from './is-logged-in.directive';
import { IsLoggedInGuard } from './is-logged-in.guard';
import { LoginComponent } from './login/login.component';
import { MenuComponent } from './menu/menu.component';
import { MenuService } from './menu/menu.service';
import { TitleService } from './title.service';
import { TitleStrategy } from '@angular/router';

@NgModule({
  imports: [
    SharedModule,
  ],
  providers: [
    AuthService,
    MenuService,
    IsLoggedInGuard,
    IsAdminGuard,
  ],
  declarations: [
    HeaderComponent,
    LoginComponent,
    MenuComponent,
    HasAuthorityDirective,
    IsAdminDirective,
    IsLoggedInDirective,
  ],
  exports: [
    HeaderComponent,
    MenuComponent,
    HasAuthorityDirective,
    IsAdminDirective,
    IsLoggedInDirective,
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        TitleService,
        {provide: TitleStrategy, useExisting: TitleService},
        {provide: ErrorHandler, useClass: ErrorHandlerService},
      ],
    };
  }
}
