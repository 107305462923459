<mat-card>
  <mat-card-header>
    <img [alt]="person.firstName + ' ' + person.lastName"
         [src]="person.email | gravatarUrl"
         mat-card-avatar>
    <mat-card-title>
      {{person.firstName}} {{person.lastName}}
    </mat-card-title>
    <mat-card-subtitle>
      {{person.email}}
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-actions>
    <button (click)="actionClick.emit('edit'); $event.stopImmediatePropagation();"
            mat-icon-button
            title="Person bearbeiten"
            type="button">
      <mat-icon>edit</mat-icon>
    </button>
    <button (click)="actionClick.emit('delete'); $event.stopImmediatePropagation();"
            mat-icon-button
            title="Person löschen"
            type="button">
      <mat-icon>delete</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
