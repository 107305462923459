import { Pipe, PipeTransform } from '@angular/core';
import { Md5 } from 'ts-md5';

const GRAVATAR_PLACEHOLDER_HASH = '00000000000000000000000000000000';

@Pipe({
  name: 'gravatarUrl',
  pure: true,
})
export class GravatarUrlPipe implements PipeTransform {
  transform(email: string | null): unknown {
    const hash = email ? Md5.hashStr(email.toLowerCase()) : GRAVATAR_PLACEHOLDER_HASH;
    return `https://www.gravatar.com/avatar/${hash}?d=mm`;
  }
}
