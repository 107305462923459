<div>
  <button (click)="prev()"
          [disabled]="!prevEnabled"
          mat-icon-button>
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
</div>
<div class="tv-image-browser__image-container">
  <img [alt]="'Image '+(activeIndex+1)" [src]="availableImages[activeIndex]"/>
</div>
<div>
  <button (click)="next()"
          [disabled]="!nextEnabled"
          mat-icon-button>
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
</div>
