import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class IsLoggedInGuard {

  constructor(
    private router: Router,
    private authService: AuthService,
    private matSnackBar: MatSnackBar,
  ) {
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.isLoggedIn$()
      .pipe(
        tap((isLoggedIn: boolean) => {
          if (!isLoggedIn) {
            this.matSnackBar.open('Bitte melde dich an um diese Seite anzusehen', 'OK');
            this.router.navigateByUrl('/');
          }
        }),
      );
  }
}
