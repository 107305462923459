import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PersonDto } from '@tv/api';

@Component({
  selector: 'tv-person-card',
  templateUrl: './person-card.component.html',
  styleUrls: ['./person-card.component.scss'],
})
export class PersonCardComponent {
  @Output() actionClick: EventEmitter<string> = new EventEmitter<string>();

  @Input() person: PersonDto;
}

