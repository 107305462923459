import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsAdminGuard, IsLoggedInGuard } from '@tv/core';
import { NewsEditorListComponent } from './news-editor-list/news-editor-list.component';

const routes: Routes = [
  {
    path: 'news',
    canActivate: [
      IsLoggedInGuard,
      IsAdminGuard,
    ],
    component: NewsEditorListComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class NewsRoutingModule {
}
