import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActCreateDto, ActDto, ActEditDto } from '@tv/api';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ActService {
  private actsSubject: Subject<ActDto[]> = new BehaviorSubject<ActDto[]>([]);

  constructor(private http: HttpClient) {
  }

  getActs(): Observable<ActDto[]> {
    return this.actsSubject
      .asObservable();
  }

  createAct(newAct: ActCreateDto): Promise<ActDto> {
    return this.http
      .post<ActDto>('/api/act', newAct)
      .pipe(
        tap(() => {
          this.loadActs();
        }),
      ).toPromise();
  }

  loadActs(): void {
    this.http
      .get<ActDto[]>('/api/act')
      .toPromise()
      .then((actDtos: ActDto[]) => this.actsSubject.next(actDtos));
  }

  getAct(id: number): Promise<ActDto> {
    return this.http
      .get<ActDto>(`/api/act/${id}`)
      .toPromise();
  }

  updateAct(act: ActEditDto): Promise<ActDto> {
    return this.http
      .put<ActDto>('/api/act', act)
      .pipe(
        tap(() => {
          this.loadActs();
        }),
      )
      .toPromise();
  }

  getActImages(actId: number): Observable<string[]> {
    return this.http
      .get<string[]>(`api/act/${actId}/images`);
  }
}
