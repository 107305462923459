import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActDto, ActEditDto, PersonListDto, RoleDto, RoleEditDto } from '@tv/api';
import { ActService } from '../act.service';

@Component({
  selector: 'tv-act-editor',
  templateUrl: './act-editor.component.html',
  styleUrls: ['./act-editor.component.scss'],
})
export class ActEditorComponent {

  editAct: ActEditDto;
  @Output() actCreated: EventEmitter<ActDto> = new EventEmitter<ActDto>();
  @Output() actUpdated: EventEmitter<ActDto> = new EventEmitter<ActDto>();
  @Output() cancel: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  constructor(private actService: ActService) {
  }

  @Input() set act(act: ActDto) {
    // copy the value -> we don't want to edit
    this.editAct = {
      roles: [],
    };
    Object.assign(this.editAct, act);

    this.editAct.roles =
      act.roles
        .map((role: RoleDto) => {
          const roleEditDto: RoleEditDto = {
            personIds: [],
          };
          roleEditDto.id = role.id;
          roleEditDto.name = role.name;
          roleEditDto.personIds = role.persons
            .map((person: PersonListDto) => person.id);
          return roleEditDto;
        });
  }

  updateAct(): void {
    if (this.editAct.id) {
      this.actService
        .updateAct(this.editAct)
        .then(this.actCreated.emit.bind(this.actCreated));
    } else {
      this.actService
        .createAct(this.editAct)
        .then(this.actUpdated.emit.bind(this.actUpdated));
    }
  }
}
