import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyOptionSelectionChange as MatOptionSelectionChange } from '@angular/material/legacy-core';
import { PersonDto, RoleEditDto } from '@tv/api';
import { PersonService } from '@tv/person';
import { combineLatest, Observable, SubscriptionLike } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'tv-role-editor',
  templateUrl: './role-editor.component.html',
  styleUrls: ['./role-editor.component.scss'],
})
export class RoleEditorComponent implements OnInit, AfterViewInit, OnDestroy, EventListenerObject {

  @Input() roles: RoleEditDto[];
  persons: PersonDto[] = [];
  myControl = new UntypedFormControl();
  filteredPersons: Observable<PersonDto[]>;

  private personsSubscription: SubscriptionLike;

  constructor(private personService: PersonService) {
  }

  ngOnInit() {

    const persons$ = this.personService
      .list()
      .pipe(
        map((persons: PersonDto[]) => {
          return persons.sort((p1, p2) => {
            return `${p1.firstName} ${p1.lastName}`.localeCompare(`${p2.firstName} ${p2.lastName}`);
          });
        }),
      );

    this.personsSubscription = persons$
      .subscribe((persons: PersonDto[]) => this.persons = persons);

    this.filteredPersons = combineLatest([
      this.myControl
        .valueChanges
        .pipe(
          startWith<string, string | null>(null),
        ),
        persons$,
      ],
    ).pipe(
      map(([val, persons]) => val ? this.filter(val, persons) : persons.slice()),
    );
  }

  ngAfterViewInit(): void {
    document.addEventListener('keydown', this);
  }

  ngOnDestroy(): void {
    if (this.personsSubscription) {
      this.personsSubscription.unsubscribe();
    }

    document.removeEventListener('keydown', this);
  }

  handleEvent(evt: KeyboardEvent): void {
    if (evt.ctrlKey && evt.altKey) {
      switch (evt.key.toLocaleLowerCase()) {
        case 'a':
          this.addNewRole();
          return;
      }
    }
  }

  addNewRole(): void {
    this.roles.push({personIds: []});
  }

  removeRole(index: number): void {
    this.roles.splice(index, 1);
  }

  toggleRoleAssignment(role: RoleEditDto, person: PersonDto, event?: MatOptionSelectionChange): void {
    if (!!event && !event.isUserInput) {
      return;
    }
    const personIndex = role.personIds.indexOf(person.id);
    if (personIndex === -1) {
      role.personIds.push(person.id);
    } else {
      role.personIds.splice(personIndex, 1);
    }
  }

  isAssignedToRole(role: RoleEditDto, person: PersonDto): boolean {
    return role.personIds.includes(person.id);
  }

  filter(val: string, persons: PersonDto[]): PersonDto[] {
    return persons.filter(person => new RegExp(val, 'gi').test(`${person.firstName} ${person.lastName}`));
  }

  moveBy(fromIdx: number, offset: number) {
    const tmp = this.roles[fromIdx + offset];
    this.roles[fromIdx + offset] = this.roles[fromIdx];
    this.roles[fromIdx] = tmp;
  }
}
