<form (submit)="updateNewsItem()">
  <mat-card>
    <mat-card-content>
      <mat-form-field>
        <input [(ngModel)]="editNewsItem.title" [placeholder]="'Titel'"
               matInput
               name="title"
               type="text"></mat-form-field>
      <div #dropZone
           (fileOver)="dropZone['fileOver']= $event"
           [ngClass]="{
          'tv-news-item-images__main-image-uploader--file-over': !!dropZone['fileOver'],
          'tv-news-item__main-image-placeholder': editNewsItem.imageUuids.length === 0
          }"
           [uploader]="uploader"
           class="tv-act-details__main-image-uploader"
           mat-card-image
           ng2FileDrop>
        <img *ngIf="editNewsItem.imageUuids.length !== 0;"
             [alt]=""
             [src]="'/api/news-item/'+editNewsItem.id+'/image/'+editNewsItem.imageUuids[0]">
      </div>
      <mat-form-field>
        <input [(ngModel)]="editNewsItem.activeFrom"
               [matDatepicker]="activeFromPicker"
               matInput
               name="activeFrom"
               placeholder="Verfügbar ab">
        <mat-datepicker-toggle [for]="activeFromPicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #activeFromPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <input [(ngModel)]="editNewsItem.activeTo"
               [matDatepicker]="activeToPicker"
               matInput
               name="activeTo"
               placeholder="Verfügbar bis">
        <mat-datepicker-toggle [for]="activeToPicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #activeToPicker></mat-datepicker>
      </mat-form-field>
      <tv-markdown-input [(ngModel)]="editNewsItem.description"
                         [name]="'description'"
                         [placeholder]="'Beschreibung'">
      </tv-markdown-input>
      <div *ngIf="editNewsItem.imageUuids.length > 1"
           class="tv-news-item-images">
        <ng-container
          *ngFor="let imageUuid of editNewsItem.imageUuids; let first = first; let last = last; let index = index;">
          <mat-card *ngIf="!first" class="tv-news-item-images__image">
            <img [alt]="'Main image of ' + editNewsItem.title"
                 [src]="'/api/news-item/'+editNewsItem.id+'/image/'+imageUuid"
                 matCardImage
            >
            <mat-card-actions class="tv-news-item-editor__action-container">
              <button (click)="moveBy(index, -1)"
                      mat-icon-button
                      type="button">
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button>
              <button (click)="editNewsItem.imageUuids.splice(index, 1);"
                      mat-icon-button
                      type="button">
                <mat-icon>delete</mat-icon>
              </button>
              <button (click)="moveBy(index, 1)"
                      [disabled]="last"
                      mat-icon-button
                      type="button">
                <mat-icon>keyboard_arrow_right</mat-icon>
              </button>
            </mat-card-actions>
          </mat-card>
        </ng-container>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button (click)="cancel.emit($event)" mat-button type="button">Abbrechen</button>
      <button mat-button type="submit">Aktualisieren</button>
    </mat-card-actions>
  </mat-card>
</form>
