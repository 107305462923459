import { NgModule } from '@angular/core';
import { MemberListComponent } from 'app/member/member-list/member-list.component';
import { SharedModule } from '../shared';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    MemberListComponent,
  ],
})
export class MemberModule {
}
