import { Injectable } from '@angular/core';
import { ɵgetDOM as getDOM } from '@angular/platform-browser';
import { FileItem, FileUploader, FileUploaderOptions } from 'ng2-file-upload';

@Injectable()
export class FileUploadService {

  prepareAutoUploader(options: FileUploaderOptions): FileUploader {
    const fileUploader = new FileUploader({
      allowedFileType: ['image'],
      autoUpload: false,
      removeAfterUpload: true,
      ...options,
    });
    fileUploader.onAfterAddingFile = (fileItem: FileItem) => {
      this.prepareCsrf(fileUploader);
      fileItem.upload();
    };
    return fileUploader;
  }

  private prepareCsrf(fileUploader: FileUploader): void {
    fileUploader.authTokenHeader = 'X-XSRF-TOKEN';
    fileUploader.authToken = getDOM().getCookie('XSRF-TOKEN');
  }

}
