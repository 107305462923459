import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { RouterModule } from '@angular/router';
import { FileUploadModule } from 'ng2-file-upload';
import { FileUploadService } from './file-upload.service';
import { ImageBrowserDirective } from './image-browser.directive';
import { ImageBrowserComponent } from './image-browser/image-browser.component';
import { MarkdownInputComponent } from './markdown/markdown-input.component';
import { MarkdownDirective } from './markdown/markdown.directive';
import { GravatarUrlPipe } from './gravatar-url.pipe';

export const MATERIAL_MODULES = [
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatInputModule,
  MatIconModule,
  MatListModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatTableModule,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MATERIAL_MODULES,
    RouterModule,
    FileUploadModule,
  ],
  declarations: [
    MarkdownDirective,
    MarkdownInputComponent,
    ImageBrowserDirective,
    ImageBrowserComponent,
    GravatarUrlPipe,
  ],
  exports: [
    FileUploadModule,
    CommonModule,
    FormsModule,
    MATERIAL_MODULES,
    RouterModule,
    MarkdownDirective,
    MarkdownInputComponent,
    ImageBrowserDirective,
    GravatarUrlPipe,
  ],
  providers: [
    FileUploadService,
    {provide: MAT_DATE_LOCALE, useValue: 'de-AT'},
  ],
})
export class SharedModule {
}
