import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NewsItemCreateDto, NewsItemDetailsDto, NewsItemEditDto, NewsItemListDto } from '@tv/api';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class NewsService {
  private newsItemsSubject: Subject<NewsItemListDto[]> = new BehaviorSubject<NewsItemListDto[]>([]);

  constructor(private http: HttpClient) {
    this.loadNewsItems();
  }

  listNewsItems$(): Observable<NewsItemListDto[]> {
    return this.newsItemsSubject.asObservable();
  }

  createNewsItem(newNewsItem: NewsItemCreateDto): Promise<NewsItemDetailsDto> {
    return this.http
      .post<NewsItemDetailsDto>('/api/news-item', newNewsItem)
      .pipe(
        tap(() => {
          this.loadNewsItems();
        }),
      )
      .toPromise();
  }

  updatNewsItem(editNewsItem: NewsItemEditDto): Promise<NewsItemDetailsDto> {
    return this.http
      .put<NewsItemDetailsDto>('/api/news-item', editNewsItem)
      .pipe(
        tap(() => {
          this.loadNewsItems();
        }),
      )
      .toPromise();
  }

  updateOrder(ids: number[]): Observable<void> {
    return this.http
      .put<void>('/api/news-item/update-order', ids)
      .pipe(
        tap(() => this.loadNewsItems()),
      );
  }

  loadDetails(id: number): Promise<NewsItemDetailsDto> {
    return this.http
      .get<NewsItemDetailsDto>(`/api/news-item/${id}`)
      .pipe(
        map(value => {
          value.activeFrom = value.activeFrom && new Date(`${value.activeFrom}Z`);
          value.activeTo = value.activeTo && new Date(`${value.activeTo}Z`);
          return value;
        }),
      )
      .toPromise();
  }

  listNewsItemsForEditing$(): Observable<NewsItemDetailsDto[]> {
    return this.http
      .get<NewsItemDetailsDto[]>(`/api/news-item/admin-list`)
      .pipe(
        map(newsItems => {
          for (const value of newsItems) {
            value.activeFrom = value.activeFrom && new Date(`${value.activeFrom}Z`);
            value.activeTo = value.activeTo && new Date(`${value.activeTo}Z`);
          }
          return newsItems;
        }),
      );
  }

  private loadNewsItems(): void {
    this.http
      .get<NewsItemListDto[]>('/api/news-item')
      .subscribe(value => this.newsItemsSubject.next(value));
  }
}

