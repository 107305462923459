import { Component, HostBinding } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActDto } from '../../../api';
import { ActCreateDto } from '../../../api/models/ActCreateDto';
import { ActService } from '../act.service';

@Component({
  selector: 'tv-act-creator',
  templateUrl: './act-creator.component.html',
  styleUrls: ['./act-creator.component.scss'],
})
export class ActCreatorComponent {

  newAct: ActCreateDto = {};

  constructor(private actService: ActService,
              private matDialogRef: MatDialogRef<ActCreatorComponent>) {
  }

  @HostBinding('class.tv-dialog') get tvDialog() {
    return true;
  }

  createAct() {
    this.actService.createAct(this.newAct)
      .then((actDto: ActDto) => {
        this.close(actDto);
      });
  }

  close(actDto?: ActDto): void {
    this.matDialogRef.close(actDto);
  }
}
