import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsLoggedInGuard } from '@tv/core/is-logged-in.guard';
import { PersonDetailsComponent } from './person-details/person-details.component';
import { PersonListPageComponent } from './person-list-page/person-list-page.component';

const routes: Routes = [
  {
    path: 'person',
    canActivate: [
      IsLoggedInGuard,
    ],
    children: [
      {
        path: ':id',
        component: PersonDetailsComponent,
        pathMatch: 'full',
      },
      {
        path: '',
        title: 'Personen',
        component: PersonListPageComponent,
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class PersonRoutingModule {
}
