import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { PersonCreateDto, PersonDto } from '@tv/api';

@Component({
  selector: 'tv-person-form',
  templateUrl: './person-form.component.html',
  styleUrls: ['./person-form.component.scss'],
})
export class PersonFormComponent {

  @Input() person: PersonCreateDto | PersonDto;

  @ViewChild('firstNameInput', {static: true}) firstNameInput: ElementRef;

  focus() {
    this.firstNameInput.nativeElement.focus();
  }
}
