<mat-table [dataSource]="newsItemsDataSource"
           [trackBy]="newsItemTrackBy">
  <ng-container matColumnDef="order">
    <mat-header-cell *matHeaderCellDef>Reihenfolge</mat-header-cell>
    <mat-cell *matCellDef="let newsItem">
      <button (click)="moveBy(newsItem, -1)"
              mat-icon-button
              type="button">
        <mat-icon>keyboard_arrow_up</mat-icon>
      </button>
      <button (click)="moveBy(newsItem, 1)"
              mat-icon-button
              type="button">
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="title">
    <mat-header-cell *matHeaderCellDef>Titel</mat-header-cell>
    <mat-cell *matCellDef="let newsItem">{{newsItem.title}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="activeFrom">
    <mat-header-cell *matHeaderCellDef>Öffentlich ab</mat-header-cell>
    <mat-cell *matCellDef="let newsItem">{{newsItem.activeFrom | date}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="activeTo">
    <mat-header-cell *matHeaderCellDef>Öffentlich bis</mat-header-cell>
    <mat-cell *matCellDef="let newsItem">{{newsItem.activeTo | date}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="author">
    <mat-header-cell *matHeaderCellDef>Verfasst von</mat-header-cell>
    <mat-cell *matCellDef="let newsItem">{{newsItem.author.firstName}} {{newsItem.author.lastName}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="edit">
    <mat-header-cell *matHeaderCellDef>Bearbeiten</mat-header-cell>
    <mat-cell *matCellDef="let newsItem">
      <button (click)="edit(newsItem)"
              mat-icon-button
              type="button">
        <mat-icon>mode_edit</mat-icon>
      </button>
    </mat-cell>
  </ng-container>


  <mat-header-row *matHeaderRowDef="visibleColumns"></mat-header-row>
  <mat-row *matRowDef="let newsItem; columns: visibleColumns"
           [ngClass]="isPublished(newsItem) ? 'published' : 'not-published'"
  ></mat-row>
</mat-table>

<button (click)="saveOrder()" mat-raised-button>Reihenfolge speichern</button>


<ng-template #editor>
  <tv-news-item-editor (cancel)="editDialog.close()"
                       (newsItemCreated)="newsItemUpdated($event)"
                       (newsItemUpdated)="newsItemUpdated($event)"
                       [newsItem]="editNewsItem"></tv-news-item-editor>
</ng-template>
