<h2>Besetzung</h2>
<div *ngFor="let role of roles;let index = index; let first = first; let last = last"
     class="tv-role-editor__role-edit">
  <div class="tv-role-editor__action-container">
    <button (click)="moveBy(index, -1)"
            [disabled]="first"
            mat-icon-button
            type="button">
      <mat-icon>keyboard_arrow_up</mat-icon>
    </button>
    <button (click)="moveBy(index, 1)"
            [disabled]="last"
            mat-icon-button
            type="button">
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
  </div>
  <div class="tv-role-editor__content-wrapper">
    <ng-container *ngIf="role.name !== '---'; else roleSeparator">
      <mat-form-field class="tv-role-editor__role-name">
        <input [(ngModel)]="role.name" [required]="true"
               matInput
               name="role"
               placeholder="Rolle">
      </mat-form-field>

      <mat-form-field>
        <input #autocomplete
               [formControl]="myControl"
               [matAutocomplete]="auto"
               [placeholder]="'weitere Personen'"
               matInput
               type="text">
      </mat-form-field>

      <mat-autocomplete #auto="matAutocomplete">
        <mat-option (onSelectionChange)="toggleRoleAssignment(role, person, $event)"
                    *ngFor="let person of filteredPersons | async"
                    [value]="''">

          <mat-icon *ngIf="isAssignedToRole(role, person)">check</mat-icon>
          <span>{{person.firstName}} {{person.lastName}}</span>
        </mat-option>
      </mat-autocomplete>

      <div class="tv-role-editor__role-assignments">
        <mat-chip-list>
          <ng-template [ngForOf]="persons" let-person ngFor>
            <mat-chip *ngIf="isAssignedToRole(role, person)">
              <span>{{person.firstName}} {{person.lastName}}</span>
              <button (click)="toggleRoleAssignment(role, person)"
                      [title]="person.firstName + ' ' + person.lastName +' von Rolle \'' + role.name + '\' entfernen'"
                      mat-icon-button
                      type="button">
                <mat-icon>remove</mat-icon>
              </button>
            </mat-chip>
          </ng-template>
        </mat-chip-list>

        <div *ngIf="role.personIds.length === 0"
             class="tv-role-editor__no-person-selected">
          Rolle '{{role.name}}' noch nicht besetzt
        </div>
      </div>
    </ng-container>
  </div>
  <div class="tv-role-editor__action-container">
    <button (click)="removeRole(index)"
            [title]="'Rolle \'' + role.name + '\' entfernen'"
            mat-icon-button
            type="button">
      <mat-icon>remove</mat-icon>
    </button>
  </div>
</div>
<button (click)="addNewRole()"
        mat-icon-button
        title="Neue Rolle hinzufügen"
        type="button">
  <mat-icon>add</mat-icon>
</button>

<ng-template #roleSeparator>
  <hr>
</ng-template>
