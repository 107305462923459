import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { PersonDto, PersonListDto } from '@tv/api';
import { PersonCreatorComponent } from '@tv/person/person-creator/person-creator.component';
import { PersonService } from '@tv/person/person-service/person.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'tv-person-list-page',
  templateUrl: './person-list-page.component.html',
  styleUrls: ['./person-list-page.component.scss'],
})
export class PersonListPageComponent implements OnInit {

  personsObservable: Observable<PersonDto[]>;

  constructor(private personService: PersonService,
              private matDialog: MatDialog,
              private matSnackBar: MatSnackBar,
              private router: Router) {
  }

  ngOnInit() {
    this.personsObservable =
      this.personService.list();

    this.personService.load();
  }

  deletePerson(person: PersonDto): void {
    this.personService
      .delete(person.id);
  }

  openPersonDetails(person: PersonDto): void {
    this.router.navigate(['person', person.id]);
  }

  openPersonCreator(person?: PersonListDto): void {
    const personPromise = person ?
      this.personService.get(person.id) :
      Promise.resolve({});

    personPromise
      .then((editPerson: PersonDto) => {
        const personEditorDialog = this.matDialog.open(PersonCreatorComponent);
        personEditorDialog.componentInstance.editPerson = editPerson;
        return personEditorDialog
          .afterClosed()
          .toPromise() as Promise<PersonDto[]>;
      })
      .then((personDtos?: PersonDto[]) => {
        if (!!personDtos && personDtos.length > 0) {
          let message: string;
          if (personDtos.length === 1) {
            const createdPerson = personDtos[0];
            message = `Person '${createdPerson.firstName} ${createdPerson.lastName}' erfolgreich erfasst`;
          } else {
            message = `${personDtos.length} Personen erfolgreich ${person ? 'aktualisiert' : 'erfasst'}`;
          }

          const notification = this.matSnackBar.open(
            message,
            'OK',
          );

          setTimeout(() => {
            notification.dismiss();
          }, 2000);
        }
      });
  }
}
