import { Component, HostBinding } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NewsItemCreateDto, NewsItemListDto } from '@tv/api';
import { NewsService } from '@tv/news/news.service';

@Component({
  selector: 'tv-news-item-creator',
  templateUrl: './news-item-creator.component.html',
  styleUrls: ['./news-item-creator.component.scss'],
})
export class NewsItemCreatorComponent {

  newNewsItem: NewsItemCreateDto = {};

  constructor(private newsService: NewsService,
              private matDialogRef: MatDialogRef<NewsItemCreatorComponent>) {
  }

  @HostBinding('class.tv-dialog') get tvDialog() {
    return true;
  }

  createNewsItem() {
    this.newsService.createNewsItem(this.newNewsItem)
      .then((newsItemListDto: NewsItemListDto) => {
        this.close(newsItemListDto);
      });
  }

  close(newsItemListDto?: NewsItemListDto): void {
    this.matDialogRef.close(newsItemListDto);
  }
}
