import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@environment';
import { TvModule } from './app/';
import * as Sentry from '@sentry/angular-ivy';
import { BUILD_INFO } from '@tv/shared';

Sentry.init(environment.sentryConfig);
Sentry.setExtra('buildInfo', BUILD_INFO);

if (environment.production) {
  enableProdMode();
}
platformBrowserDynamic()
  .bootstrapModule(TvModule)
  .then(() => console.log('Successfully bootstrapped'))
  .catch(err => console.error(err));
