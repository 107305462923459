import { BUILD_INFO } from '@tv/shared';
import { BrowserTracing, Replay, routingInstrumentation } from '@sentry/angular-ivy';
import { Environment } from '@typings';

export const environment: Environment = {
  production: true,
  sentryConfig: {
    dsn: 'https://a59ac7bd4941468faff5b49154501579@o138085.ingest.sentry.io/305522',
    maxBreadcrumbs: 50,
    debug: false,
    environment: (window.location.host === 'staging.theater-vogelweide.at') ? 'staging' : 'production',
    release: BUILD_INFO.VERSION.STRING,
    integrations: [
      new BrowserTracing({
        routingInstrumentation,
      }),
      new Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  },
};
