import { NgModule } from '@angular/core';
import { CoreModule } from '@tv/core';
import { SharedModule } from '@tv/shared';
import { NewsEditorListComponent } from './news-editor-list/news-editor-list.component';
import { NewsItemCreatorComponent } from './news-item-creator/news-item-creator.component';
import { NewsItemEditorComponent } from './news-item-editor/news-item-editor.component';
import { NewsItemComponent } from './news-item/news-item.component';
import { NewsRoutingModule } from './news-routing.module';
import { NewsComponent } from './news.component';
import { NewsService } from './news.service';
import { NewsUiService } from './news.ui-service';

@NgModule({
  imports: [
    CoreModule,
    SharedModule,
    NewsRoutingModule,
  ],
  declarations: [
    NewsComponent,
    NewsItemComponent,
    NewsItemCreatorComponent,
    NewsItemEditorComponent,
    NewsEditorListComponent,
  ],
  providers: [
    NewsService,
    NewsUiService,
  ],
})
export class NewsModule {
}
