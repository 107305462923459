import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PersonDto } from '@tv/api';
import { TypedChange } from '@tv/shared';

export interface PersonListChanges extends SimpleChanges {
  persons: TypedChange<PersonDto[]>;
}

@Component({
  selector: 'tv-person-list',
  templateUrl: './person-list.component.html',
  styleUrls: ['./person-list.component.scss'],
})
export class PersonListComponent implements OnChanges {

  @Input() persons: PersonDto[] = [];
  @Output() selectPerson: EventEmitter<PersonDto> = new EventEmitter<PersonDto>();
  @Output() deletePerson: EventEmitter<PersonDto> = new EventEmitter<PersonDto>();
  @Output() editPerson: EventEmitter<PersonDto> = new EventEmitter<PersonDto>();

  searchText = '';
  filteredPersons: PersonDto[] = [];

  ngOnChanges(): void {
    this.filterPersonList();
  }

  onSearchTextChange(searchText: string): void {
    this.searchText = searchText;
    this.filterPersonList();
  }

  personAction(event: string, person: PersonDto): void {
    switch (event) {
      case 'delete':
        this.deletePerson.emit(person);
        break;
      case 'edit':
        this.editPerson.emit(person);
        break;
      default:
        throw new Error(`Unsupported person action '${event}'`);
    }
  }

  private filterPersonList(): void {
    if (!this.searchText.trim()) {
      this.filteredPersons = this.persons;
      return;
    }

    this.filteredPersons = this.persons
      .filter(p => `${p.firstName} ${p.lastName} ${p.email}`.includes(this.searchText));
  }
}
