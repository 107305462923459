<header class="tv-dialog__header">
  <div class="tv-dialog__header__title">
    {{editPerson.id ? 'Person bearbeiten' : 'Neue Person erfassen'}}
  </div>
</header>
<form (submit)="createOrUpdatePerson()">
  <tv-person-form #personForm [person]="editPerson"></tv-person-form>
  <div class="form-actions">
    <mat-checkbox *ngIf="!editPerson.id"
                  [(ngModel)]="createAnother"
                  [ngModelOptions]="{standalone: true}">
      weitere Person erfassen
    </mat-checkbox>
    <button (click)="editPerson.address = {};" *ngIf="!editPerson.address" mat-button type="button">Addresse hinzufügen
    </button>
    <button (click)="editPerson.address = null;" *ngIf="editPerson.address" mat-button type="button">Addresse entfernen
    </button>
    <button (click)="close(createdPersons)" mat-button type="button">Abbrechen</button>
    <button mat-button type="submit">{{editPerson.id ? 'Aktualisieren' : 'Hinzufügen'}}</button>
  </div>
</form>
