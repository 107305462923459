import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarConfig as MatSnackBarConfig,
} from '@angular/material/legacy-snack-bar';
import { SubscriptionLike } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActDto } from '@tv/api';
import { ActCreatorComponent } from './act-creator/act-creator.component';
import { ActService } from './act.service';

@Component({
  selector: 'tv-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss'],
})
export class ArchiveComponent implements OnInit, OnDestroy {
  groupedActs: {
    years: number[],
    [year: number]: ActDto[],
  };
  private actsSubscription: SubscriptionLike;

  constructor(private matDialog: MatDialog,
              private actService: ActService,
              private matSnackBar: MatSnackBar) {
  }

  openNewActDialog(): void {
    this.matDialog.open(ActCreatorComponent)
      .afterClosed()
      .toPromise()
      .then((actDto: ActDto) => {
        if (actDto) {
          const notification = this.matSnackBar.open(
            `Stück '${actDto.title}' erfoglreich erfasst!`,
            'OK',
            new MatSnackBarConfig(),
          );

          setTimeout(() => {
            notification.dismiss();
          }, 2000);
        }
      });
  }

  ngOnInit(): void {
    this.actsSubscription = this.actService.getActs()
      .pipe(
        map((acts: ActDto[]) => {
          const years: number[] = [];
          const groupedActs: typeof this.groupedActs = {
            years: years,
          };
          for (const act of acts) {
            if (!groupedActs[act.year]) {
              years.push(act.year);
              groupedActs[act.year] = [];
            }
            groupedActs[act.year].push(act);
          }
          return groupedActs;
        }),
      )
      .subscribe((groupedActs) => this.groupedActs = groupedActs);
    this.actService.loadActs();
  }

  ngOnDestroy(): void {
    if (this.actsSubscription) {
      this.actsSubscription.unsubscribe();
    }
  }
}
