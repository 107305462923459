<ng-container *ngIf="newsItemList$ | async; let newsItemList; else loading">
  <tv-news-item *ngFor="let newsItem of newsItemList" [newsItem]="newsItem">
  </tv-news-item>
  <ng-container *ngIf="!newsItemList.length">
    <div>Aktuell sind leider keine Neuigkeiten verfügbar</div>
  </ng-container>
</ng-container>
<ng-template #loading>
  <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
  <div>Neuigkeiten werden geladen...</div>
</ng-template>

<button (click)="openNewNewsDialog()" *tvIsAdmin
        aria-label="Neuigkeit erfassen"
        class="tv-fab"
        mat-fab>
  <mat-icon>add</mat-icon>
</button>
