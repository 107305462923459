<div class="form-controls">
  <mat-form-field>
    <input #firstNameInput
           [(ngModel)]="person.firstName"
           matInput
           name="firstName"
           placeholder="Vorname"
           required>
  </mat-form-field>
  <mat-form-field>
    <input [(ngModel)]="person.lastName"
           matInput
           name="lastName"
           placeholder="Nachname"
           required>
  </mat-form-field>
  <mat-form-field>
    <input [(ngModel)]="person.email"
           matInput
           name="email"
           placeholder="E-Mail">
  </mat-form-field>
</div>
<div class="form-controls">
  <mat-form-field>
    <input [(ngModel)]="person.phone"
           matInput
           name="phone"
           placeholder="Telefon">
  </mat-form-field>
  <mat-form-field>
    <input [(ngModel)]="person.mobilePhone"
           matInput
           name="mobilePhone"
           placeholder="Mobil">
  </mat-form-field>
</div>
<div *ngIf="person.address" class="form-controls">
  <mat-form-field>
    <input [(ngModel)]="person.address.street"
           matInput
           name="street"
           placeholder="Straße"
           required>
  </mat-form-field>
  <mat-form-field>
    <input [(ngModel)]="person.address.houseNumber"
           matInput
           name="houseNumber"
           placeholder="Hausnummer"
           required>
  </mat-form-field>
  <mat-form-field>
    <input [(ngModel)]="person.address.zipcode"
           matInput
           name="zipcode"
           placeholder="Postleitzahl"
           required>
  </mat-form-field>
  <mat-form-field>
    <input [(ngModel)]="person.address.city"
           matInput
           name="city"
           placeholder="Stadt"
           required>
  </mat-form-field>
</div>
