import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NewsItemDetailsDto, NewsItemEditDto } from '@tv/api';
import { NewsService } from '@tv/news/news.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'tv-news-editor-list',
  templateUrl: './news-editor-list.component.html',
  styleUrls: ['./news-editor-list.component.scss'],
})
export class NewsEditorListComponent implements OnInit {

  visibleColumns = ['order', 'title', 'activeFrom', 'activeTo', 'author', 'edit'];
  newsItems: NewsItemDetailsDto[] = [];
  newsItemsDataSource = new BehaviorSubject(this.newsItems);
  editNewsItem?: NewsItemDetailsDto;
  editDialog: MatDialogRef<unknown>;
  @ViewChild('editor', {static: true}) editorTemplate: TemplateRef<unknown>;

  constructor(private newsService: NewsService,
              private matDialog: MatDialog) {
  }

  newsItemTrackBy = (_: number, newsItem: NewsItemDetailsDto) => {
    return newsItem.id;
  };

  ngOnInit(): void {
    this.reload();
  }

  edit(newsItem: NewsItemDetailsDto) {
    this.editNewsItem = newsItem;
    this.editDialog = this.matDialog.open(this.editorTemplate);
    this.editDialog.afterClosed().subscribe(() => {
      this.editNewsItem = null;
      this.editDialog = null;
    });
  }

  moveBy(newsItem: NewsItemDetailsDto, offset: number): void {
    const fromIdx = this.newsItems.indexOf(newsItem);
    const tmp = this.newsItems[fromIdx + offset];
    this.newsItems[fromIdx + offset] = this.newsItems[fromIdx];
    this.newsItems[fromIdx] = tmp;
    this.refresh();
  }

  saveOrder(): void {
    this.newsService
      .updateOrder(this.newsItems.map(value => value.id))
      .subscribe(() => this.reload());
  }

  refresh(): void {
    this.newsItemsDataSource.next(this.newsItems);
  }

  reload(): void {
    this.newsService.listNewsItemsForEditing$()
      .subscribe(newsItems => {
        this.newsItems = newsItems;
        this.refresh();
      });
  }

  newsItemUpdated(newsItem: NewsItemEditDto) {
    this.newsService
      .updatNewsItem(newsItem)
      .then(() => this.editDialog.close())
      .then(() => this.reload());
  }

  isPublished(newsItem: NewsItemDetailsDto): boolean {
    const now = new Date();

    if (newsItem.activeFrom && now < newsItem.activeFrom) {
      return false;
    }

    return !(newsItem.activeTo && now > newsItem.activeTo);
  }

}
