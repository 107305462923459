import { Component, HostBinding } from '@angular/core';
import { MenuService } from './menu.service';

@Component({
  selector: 'tv-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {

  constructor(private menuService: MenuService) {
  }

  @HostBinding('class.open') get open() {
    return this.menuService.open;
  }
}
