<ng-container *ngIf="roles && roles.length; else noRoles">
  <h2>Besetzung</h2>
</ng-container>
<ng-template #noRoles>
  <div class="tv-role-viewer__no-roles">leider ist noch keine Besetzungliste verfügbar</div>
</ng-template>
<div *ngFor="let role of roles" class="tv-role-viewer__role-view">
  <ng-container *ngIf="role.name !== '---'; else roleSeparator">
    <div class="tv-role-viewer__role-view__role">{{role.name}}</div>
    <div class="tv-role-viewer__role-view__persons">
      <div *ngFor="let person of role.persons"
           class="tv-role-viewer__role-view__person">{{person.firstName}} {{person.lastName}}
      </div>
      <div *ngIf="role.persons.length === 0"
           class="tv-role-viewer__role-view__no-person">nicht besetzt
      </div>
    </div>
  </ng-container>
</div>
<ng-template #roleSeparator>
  <hr>
</ng-template>
