import { Component, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PersonCreateDto, PersonDto } from '@tv/api';
import { PersonFormComponent } from '../person-form/person-form.component';
import { PersonService } from '../person-service/person.service';

@Component({
  selector: 'tv-person-creator',
  templateUrl: './person-creator.component.html',
  styleUrls: ['./person-creator.component.scss'],
})
export class PersonCreatorComponent {

  @ViewChild('personForm', {static: true}) personForm: PersonFormComponent;
  editPerson: PersonCreateDto | PersonDto = {};

  createAnother: boolean;
  createdPersons: PersonDto[] = [];

  constructor(private personService: PersonService,
              private matDialogRef: MatDialogRef<PersonCreatorComponent>) {
  }

  close(persons?: PersonDto[]): void {
    this.matDialogRef.close(persons);
  }

  reset(): void {
    this.editPerson = {};
    this.personForm.focus();
  }

  createOrUpdatePerson(): void {
    let result: Promise<PersonDto>;

    if (this.editPerson.id) {
      result = this.personService
        .udpate(this.editPerson);
    } else {
      result = this.personService
        .create(this.editPerson);
    }

    result
      .then((person: PersonDto) => {
        this.reset();
        this.createdPersons.push(person);
        if (!this.createAnother) {
          this.close(this.createdPersons);
        }
      });
  }
}
