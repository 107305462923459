<form (submit)="update()">
  <mat-card>
    <mat-card-content>
      <tv-person-form [person]="editPerson"></tv-person-form>
    </mat-card-content>
    <mat-card-actions>
      <button (click)="editPerson.address = {};" *ngIf="!editPerson.address" mat-button type="button">Addresse
        hinzufügen
      </button>
      <button (click)="editPerson.address = null;" *ngIf="editPerson.address" mat-button type="button">Addresse
        entfernen
      </button>
      <button (click)="cancel.emit($event)" mat-button type="button">Abbrechen</button>
      <button mat-button type="submit">Aktualisieren</button>
    </mat-card-actions>
  </mat-card>
</form>
