import { NgModule } from '@angular/core';
import { SharedModule } from '@tv/shared';
import { ImprintComponent } from './imprint/imprint.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    ImprintComponent,
  ],
})
export class ImprintModule {
}
