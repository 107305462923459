import { Component, Signal } from '@angular/core';
import { MenuService } from '../menu/menu.service';
import { TitleService } from '../title.service';

@Component({
  selector: 'tv-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  pageTitle: Signal<string | null>;

  constructor(
    titleService: TitleService,
    private menuService: MenuService,
  ) {
    this.pageTitle = titleService.getPageTitle();
  }

  toggleMenu(): void {
    this.menuService.open = !this.menuService.open;
  }
}
