<tv-person-list (deletePerson)="deletePerson($event)"
                (editPerson)="openPersonCreator($event)"
                (selectPerson)="openPersonDetails($event)"
                [persons]="personsObservable | async"></tv-person-list>
<button (click)="openPersonCreator()" *tvIsAdmin
        aria-label="Neue Person erfassen"
        class="tv-fab"
        mat-fab>
  <mat-icon>add</mat-icon>
</button>
