import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarConfig as MatSnackBarConfig,
} from '@angular/material/legacy-snack-bar';
import { NewsItemListDto } from '@tv/api';
import { NewsItemCreatorComponent } from '@tv/news/news-item-creator/news-item-creator.component';

@Injectable()
export class NewsUiService {

  constructor(private matDialog: MatDialog,
              private matSnackBar: MatSnackBar) {
  }

  openNewNewsDialog(): void {
    this.matDialog.open(NewsItemCreatorComponent)
      .afterClosed()
      .toPromise()
      .then((newsItem: NewsItemListDto) => {
        if (newsItem) {
          const notification = this.matSnackBar.open(
            `News '${newsItem.title}' erfoglreich erfasst!`,
            'OK',
            new MatSnackBarConfig(),
          );

          setTimeout(() => {
            notification.dismiss();
          }, 2000);
        }
      });
  }
}

