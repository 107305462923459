import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'tv-markdown-input',
  templateUrl: './markdown-input.component.html',
  styleUrls: [
    './markdown-input.component.scss',
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MarkdownInputComponent),
      multi: true,
    },
  ],
})
export class MarkdownInputComponent implements ControlValueAccessor {
  @Input() name: string;
  @Input() placeholder: string;
  @Input() required = false;
  @Input() disabled: boolean;
  preview = false;

  @Input() value: string;

  @Output() valueChange = new EventEmitter<string>();

  onChange(value: string) {
    this.writeValue(value);
    this.valueChange.emit(value);
  }

  writeValue(value: string) {
    this.value = value;
  }

  registerOnChange(fn: (_: string) => void) {
    const original = this.onChange.bind(this);
    this.onChange = (_: string) => {
      fn(_);
      original(_);
    };
  }

  registerOnTouched() {
    // we are not interested in the touched state
  }
}
